$(() => {
	$("#phone1").mask("+7(999) 999-99-99");
	$("#phone2").mask("+7(999) 999-99-99");
});

$(function() {
	// при нажатии на кнопку вправо на слайдере
	$('.case-slider-next').click(function() {
		$('.case-picture').animate({
			scrollLeft: "+=600px"
		}, "slow");
	})
});

$(function() {
	// при нажатии на кнопку влево на слайдере
	$('.case-slider-prev').click(function() {
		$('.case-picture').animate({
			scrollLeft: "-=600px"
		}, "slow");
	})
});

$(function() {
	// при нажатии на "рассчитать стоимость" в мобильной версии
	$('.menu-callback').click(function() {
		$('.sideBar').toggleClass('sideBar_visible');
		$('.rightSideBar').toggleClass('rightSideBar_visible');
		$('.rightSideBar-overlay').toggleClass('overlay_visible');

		// $('#openCallback').modal('show');
	});
});

$(function() {

	$('.btn1').click(function() {
		document.querySelector('#code').value = "NOSPAM";
		document.querySelector('#code2').value = "NOSPAM";
		//console.log('NOSPAM');
	});
});
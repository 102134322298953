class Accordion {
    constructor(obj, postfix = '') {
        this.obj = obj;
        this.postfix = postfix;
        this.head = obj.find('.accordion' + this.postfix + '-head');
        this.body = obj.find('.accordion' + this.postfix + '-body');
        this.head.click(this.toggle.bind(this));
    }

    toggle() {
        this.head.toggleClass('accordion' + this.postfix + '-head_open');
        this.body.toggleClass('accordion' + this.postfix + '-body_open');
    }
}

$(function () {
    $('.accordion').each(function () {
        new Accordion($(this));
    });

    $('.accordion2').each(function () {
        new Accordion($(this), '2');
    });

    $('.accordion3').each(function () {
        new Accordion($(this), '3');
    });
});
$(() => {
	// $('.slider').slick({
	// 	infinite: true,
	// 	slidesToShow: 3,
	// 	slidesToScroll: 1,
	// 	arrows: false,
	//
	// 	responsive: [
	// 		{
	// 			breakpoint: 992,
	// 			settings: {
	// 				slidesToShow: 3
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 500,
	// 			settings: {
	// 				slidesToShow: 2
	// 			}
	// 		}
	// 	]
	// });

	$('.slider-project').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.slick-prev'),
		nextArrow: $('.slick-next')
	});
});
let elementHeight = $("header").height();

$(window).scroll(function() {
	if ($(window).scrollTop() > 800) {
		$('header').addClass("sticky");
		$("body").css({
			"paddingTop": elementHeight
		})
	} else {
		$('header').removeClass("sticky");
		$("body").css({
			"paddingTop": "0"
		})
	}
});
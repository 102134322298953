$(function(){
	$(".beam-scroll").scroll(function(){
		$(".beam-table")
			.scrollLeft($(".beam-scroll").scrollLeft());
	});
	$(".beam-table").scroll(function(){
		$(".beam-scroll")
			.scrollLeft($(".beam-table").scrollLeft());
	});
});


(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
class Accordion {
    constructor(obj, postfix = '') {
        this.obj = obj;
        this.postfix = postfix;
        this.head = obj.find('.accordion' + this.postfix + '-head');
        this.body = obj.find('.accordion' + this.postfix + '-body');
        this.head.click(this.toggle.bind(this));
    }

    toggle() {
        this.head.toggleClass('accordion' + this.postfix + '-head_open');
        this.body.toggleClass('accordion' + this.postfix + '-body_open');
    }
}

$(function () {
    $('.accordion').each(function () {
        new Accordion($(this));
    });

    $('.accordion2').each(function () {
        new Accordion($(this), '2');
    });

    $('.accordion3').each(function () {
        new Accordion($(this), '3');
    });
});
},{}],2:[function(require,module,exports){
// $(() => {
//
// let btn = document.querySelector("#fullButton");
// btn.onclick = function(e) {
// 	let element = document.querySelector(".full_block");
// 	if(element.classList.contains("full_show")) {
// 		element.classList.remove("full_show");
// 	} else {
// 		element.classList.add("full_show");
// 	}
// }
//
// });
},{}],3:[function(require,module,exports){
$(document).ready(function(){
	$(".anchors").on("click","a", function (event) {
		event.preventDefault();
		let id  = $(this).attr('href'),
			top = $(id).offset().top;
		$('body,html').animate({scrollTop: top}, 1000);
	});
});
},{}],4:[function(require,module,exports){
$(() => {
	new CircleType(document.getElementById('demo3'));
});
},{}],5:[function(require,module,exports){
let elementHeight = $("header").height();

$(window).scroll(function() {
	if ($(window).scrollTop() > 800) {
		$('header').addClass("sticky");
		$("body").css({
			"paddingTop": elementHeight
		})
	} else {
		$('header').removeClass("sticky");
		$("body").css({
			"paddingTop": "0"
		})
	}
});
},{}],6:[function(require,module,exports){
$(() => {
	$("#phone1").mask("+7(999) 999-99-99");
	$("#phone2").mask("+7(999) 999-99-99");
});

$(function() {
	// при нажатии на кнопку вправо на слайдере
	$('.case-slider-next').click(function() {
		$('.case-picture').animate({
			scrollLeft: "+=600px"
		}, "slow");
	})
});

$(function() {
	// при нажатии на кнопку влево на слайдере
	$('.case-slider-prev').click(function() {
		$('.case-picture').animate({
			scrollLeft: "-=600px"
		}, "slow");
	})
});

$(function() {
	// при нажатии на "рассчитать стоимость" в мобильной версии
	$('.menu-callback').click(function() {
		$('.sideBar').toggleClass('sideBar_visible');
		$('.rightSideBar').toggleClass('rightSideBar_visible');
		$('.rightSideBar-overlay').toggleClass('overlay_visible');

		// $('#openCallback').modal('show');
	});
});

$(function() {

	$('.btn1').click(function() {
		document.querySelector('#code').value = "NOSPAM";
		document.querySelector('#code2').value = "NOSPAM";
		//console.log('NOSPAM');
	});
});
},{}],7:[function(require,module,exports){
$(() => {
	let obj = $('.rightSideBar');
	let content = $('.rightSideBar-content');
	let obj_ovrly = $('.rightSideBar-overlay');

	function toggle(e) {
		let target = $(e.currentTarget);
		if (!target.is('.rightSideBar') && !target.is('[href]')) {
			target = target.parents('.rightSideBar');
		}
		let id = target.attr('id') || target.attr('href').replace('#', '');

		obj.filter('[id="' + id + '"]').toggleClass('rightSideBar_visible');
		obj_ovrly.toggleClass('overlay_visible');
	}

	$('.js-rightSideBarCtrl').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);


});

},{}],8:[function(require,module,exports){
$(() => {
	let obj = $('.rightSideBar2');
	let content = $('.rightSideBar2-content');
	let obj_ovrly = $('.rightSideBar-overlay');

	function toggle(e) {
		let target = $(e.currentTarget);
		if (!target.is('.rightSideBar2') && !target.is('[href]')) {
			target = target.parents('.rightSideBar2');
		}
		let id = target.attr('id') || target.attr('href').replace('#', '');

		obj.filter('[id="' + id + '"]').toggleClass('rightSideBar2_visible');
		obj_ovrly.toggleClass('overlay_visible');
	}

	$('.js-rightSideBarCtrl2').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);


});

},{}],9:[function(require,module,exports){
// $(() => {
// 	let obj = $('.rightSideBar2');
// 	let content = $('.rightSideBar2-content');
//
// 	function toggle(e) {
// 		let target = $(e.currentTarget);
// 		if (!target.is('.rightSideBar2') && !target.is('[href]')) {
// 			target = target.parents('.rightSideBar2');
// 		}
// 		let id = target.attr('id') || target.attr('href').replace('#', '');
//
// 		obj.filter('[id="' + id + '"]').toggleClass('rightSideBar2_visible');
// 	}
//
// 	$('.js-rightSideBarCtrl2').click(toggle);
// 	content.click((e) => {
// 		e.stopPropagation();
// 	});
// 	obj.click(toggle);
//
//
// });
},{}],10:[function(require,module,exports){
$(function(){
	$(".beam-scroll").scroll(function(){
		$(".beam-table")
			.scrollLeft($(".beam-scroll").scrollLeft());
	});
	$(".beam-table").scroll(function(){
		$(".beam-scroll")
			.scrollLeft($(".beam-table").scrollLeft());
	});
});


},{}],11:[function(require,module,exports){
$(document).ready(function() { //
	$('.selectpicker').selectpicker('render');

	// $('.dropdown > a.dropdown-toggle').on('click', function(event) {
	// 	$(this).parent().toggleClass('show')
	// 	$(this).attr('aria-expanded', $(this).attr('aria-expanded') == 'false' ? 'true' : 'false'); //add true or false
	// 	$("div[aria-labelledby=" + $(this).attr('id') + "]").toggleClass('show') //add class/remove
	// });
	//
	// $('body').on('click', function(e) {
	// 	//check if the click occur outside `myDD` tag if yes ..hide menu
	// 	if (!$('.dropdown').is(e.target) &&
	// 		$('.dropdown').has(e.target).length === 0 &&
	// 		$('.show').has(e.target).length === 0
	// 	) {
	// 		//remove clases and add attr
	// 		$('.dropdown').removeClass('show')
	// 		$('.dropdown > a').attr('aria-expanded', 'false');
	// 		$(".dropdown").children('div.dropdown-menu').removeClass('show')
	// 	}
	// });
});
},{}],12:[function(require,module,exports){
$(() => {
	let obj = $('.sideBar');
	let content = $('.sideBar-content');

	function toggle() {
		obj.toggleClass('sideBar_visible');
	}

	$('.js-sideBarCtrl').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);



});


},{}],13:[function(require,module,exports){
$(() => {
	// $('.slider').slick({
	// 	infinite: true,
	// 	slidesToShow: 3,
	// 	slidesToScroll: 1,
	// 	arrows: false,
	//
	// 	responsive: [
	// 		{
	// 			breakpoint: 992,
	// 			settings: {
	// 				slidesToShow: 3
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 500,
	// 			settings: {
	// 				slidesToShow: 2
	// 			}
	// 		}
	// 	]
	// });

	$('.slider-project').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.slick-prev'),
		nextArrow: $('.slick-next')
	});
});
},{}],14:[function(require,module,exports){
$(() => {

    const toggleButton = document.querySelector('.js-toggle');
    const videoPlayer = document.getElementById('fullSizeVideo');
    const textBlock = document.getElementById('text');
    const videoOverlay = document.getElementById('video-overlay');

    const toggleVideo = (event) => {

        event.preventDefault();
        videoPlayer.controls = 'controls';
        videoPlayer.muted = !videoPlayer.muted;
        textBlock.style.display = 'none';
        videoOverlay.style.display = 'none';
        videoPlayer.volume = 0.9;
        videoPlayer.setAttribute("src", "../img/video/ecohouse-full.mp4");
        videoPlayer.play();
    };

    toggleButton.addEventListener('click', toggleVideo);

});

},{}],15:[function(require,module,exports){
require('./block/sideBar');
require('./block/rightSideBar');
require('./block/rightSideBar2');
require('./block/circletype');
require('./block/anchors');
require('./block/video');
require('./block/header');
require('./block/mask');
require('./block/rightSuccess');
require('./block/select');
require('./block/additionally');
require('./block/scrollbar');
require('./block/slider');
require('./block/accordion');
},{"./block/accordion":1,"./block/additionally":2,"./block/anchors":3,"./block/circletype":4,"./block/header":5,"./block/mask":6,"./block/rightSideBar":7,"./block/rightSideBar2":8,"./block/rightSuccess":9,"./block/scrollbar":10,"./block/select":11,"./block/sideBar":12,"./block/slider":13,"./block/video":14}]},{},[15])


$(() => {
	let obj = $('.rightSideBar2');
	let content = $('.rightSideBar2-content');
	let obj_ovrly = $('.rightSideBar-overlay');

	function toggle(e) {
		let target = $(e.currentTarget);
		if (!target.is('.rightSideBar2') && !target.is('[href]')) {
			target = target.parents('.rightSideBar2');
		}
		let id = target.attr('id') || target.attr('href').replace('#', '');

		obj.filter('[id="' + id + '"]').toggleClass('rightSideBar2_visible');
		obj_ovrly.toggleClass('overlay_visible');
	}

	$('.js-rightSideBarCtrl2').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);


});

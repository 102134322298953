$(document).ready(function() { //
	$('.selectpicker').selectpicker('render');

	// $('.dropdown > a.dropdown-toggle').on('click', function(event) {
	// 	$(this).parent().toggleClass('show')
	// 	$(this).attr('aria-expanded', $(this).attr('aria-expanded') == 'false' ? 'true' : 'false'); //add true or false
	// 	$("div[aria-labelledby=" + $(this).attr('id') + "]").toggleClass('show') //add class/remove
	// });
	//
	// $('body').on('click', function(e) {
	// 	//check if the click occur outside `myDD` tag if yes ..hide menu
	// 	if (!$('.dropdown').is(e.target) &&
	// 		$('.dropdown').has(e.target).length === 0 &&
	// 		$('.show').has(e.target).length === 0
	// 	) {
	// 		//remove clases and add attr
	// 		$('.dropdown').removeClass('show')
	// 		$('.dropdown > a').attr('aria-expanded', 'false');
	// 		$(".dropdown").children('div.dropdown-menu').removeClass('show')
	// 	}
	// });
});
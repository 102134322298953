require('./block/sideBar');
require('./block/rightSideBar');
require('./block/rightSideBar2');
require('./block/circletype');
require('./block/anchors');
require('./block/video');
require('./block/header');
require('./block/mask');
require('./block/rightSuccess');
require('./block/select');
require('./block/additionally');
require('./block/scrollbar');
require('./block/slider');
require('./block/accordion');